import { merge, ThemeUIStyleObject } from "theme-ui"
import tailwind from "@theme-ui/preset-tailwind"

declare module "theme-ui" {
  interface Theme {
    footer?: ThemeUIStyleObject
    texts?: ThemeUIStyleObject
  }
}

const theme = merge(tailwind, {
  config: {
    initialColorModeName: `dark`,
  },
  colors: {
    primary: "#333D48", // A dark slate gray as the primary color, offering a clean and modern foundation
    secondary: "#4A5564", // A slightly lighter shade of dark gray for secondary elements, providing subtle contrast
    text: "#F4F4F2", // A very light gray, almost white, for text to ensure high readability on dark backgrounds
    heading: "#EAEAEA", // A light gray for headings, creating a modern, sleek look against the dark background
    background: "#1E2125", // A deep, dark gray, almost black, for the background, exuding contemporary elegance
    divider: "#3A3F44", // A dark charcoal for dividers that blend in with the theme but still delineate sections
    textMuted: "#B2B2B2", // A medium gray for muted text, offering a softer contrast for secondary information
    icon_brightest: "#FFFFFF", // Pure white for the brightest icons, ensuring maximum visibility and a clean look
    icon_darker: "#5F6975", // A muted navy for icons needing less prominence, still modern and understated
    icon_darkest: "#4D5B69", // A dark, desaturated blue for icons with moderate visibility, adding sophistication
    icon_red: "#E06C75", // A muted red for icons needing to convey caution or attention, with a modern touch
    icon_blue: "#61AFEF", // A vibrant but not overly bright blue for icons representing trust and calm
    icon_orange: "#D19A66", // A muted, earthy orange for standout or highlight icons, adding warmth without excess saturation
    icon_yellow: "#E5C07B", // A soft, muted yellow for attention-grabbing or energizing icons, avoiding harshness
    icon_pink: "#D67D9A", // A desaturated pink for creativity or passion icons, subtle yet modern
    icon_purple: "#C678DD", // A muted purple for icons conveying wisdom or luxury, modern and chic
    icon_green: "#98C379", // A soft, desaturated green for growth or success icons, offering a hint of vibrancy
  },
  
  
  
  
  breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
  styles: {
    root: {
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
      color: `text`,
      backgroundColor: `background`,
      WebkitTextSizeAdjust: `100%`,
      a: {
        color: `primary`,
        textDecoration: `none`,
        transition: `all 0.3s ease-in-out`,
        "&:hover": {
          color: `primary`,
          textDecoration: `none`,
        },
      },
      img: {
        borderStyle: `none`,
      },
      pre: {
        fontFamily: `monospace`,
        fontSize: `1em`,
      },
    },
    p: {
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`,
    },
    blockquote: {
      marginLeft: 0,
      p: {
        fontSize: [3, 4],
        fontWeight: `medium`,
        color: `linear-gradient(to right, #C678DD, #98C379);`,
      },
    },
    h1: {
      
        fontSize: ['15vw', '15vw', '10vw'], // Explicitly larger font sizes for different breakpoints
        mt: 3, // Increased top margin for better spacing
        mb: 4, // Increased bottom margin for better spacing
        textShadow: `
          1px 1px 0px #FF0000, /* Red */
          2px 2px 0px #FF7F00, /* Orange */
          3px 3px 0px #FFFF00, /* Yellow */
          4px 4px 0px #00FF00, /* Green */
          5px 5px 0px #0000FF, /* Blue */
          6px 6px 0px #4B0082, /* Indigo */
          7px 8px 0px #9400D3  /* Violet */
        `, // Added rainbow glow effect
        letterSpacing: `wide`, // Kept the original letter spacing
        color: `heading`, // Kept color as purple
        textAlign: `center` // Kept text alignment to center
      
    },
    h2: {
      fontSize: [4, 5, 6],
      mt: 2,
      mb: 2,
      color: `heading`,
    },
    h3: {
      fontSize: [3, 4, 5],
      mt: 3,
      color: `heading`,
    },
    h4: {
      fontSize: [2, 3, 4],
      color: `heading`,
    },
    h5: {
      fontSize: [1, 2, 3],
      color: `heading`,
    },
    h6: {
      fontSize: 1,
      mb: 2,
      color: `heading`,
    },
  },
  layout: {
    container: {
      maxWidth: `5xl`,
    },
  },
  buttons: {
    toggle: {
      color: `background`,
      border: `none`,
      backgroundColor: `text`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 3,
      py: 2,
      ml: 3,
    },
  },
  footer: {
    textAlign: `center`,
    display: `block`,
    position: `absolute`,
    bottom: 0,
    color: `textMuted`,
    px: [2, 3],
    py: [3, 4],
  },
  texts: {
    bigger: {
      p: {
        fontSize: [2, 3, 4],
      },
    },
  },
})

export default theme
